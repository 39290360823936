<template>
  <barcodes-list
    :table-header="tableHeader"
    :barcodes="allBarcode"
  ></barcodes-list>
</template>

<script>
import BarcodesList from './BarcodesList.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MainBarcode',
  components: {
    BarcodesList,
  },
  data() {
    return {
      tableHeader: ['id', 'barcode_value'],
    };
  },
  computed: {
    ...mapGetters(['allBarcode']),
  },
  created() {
    this.fetchAllBarcodes();
  },
  methods: {
    ...mapActions(['fetchAllBarcodes']),
  },
};
</script>

<style scoped></style>
